<template>
  <div>
    <div class="out-wrap" id="pdfDom" v-watermark="{ text: '电子合同，打印无效',id:'pdfDom' }" ref="pdfDom">
      <div class="page" style="padding-top:20px">
        <div class="part">
          <p class="bold">编号:{{ shareDate.contractCode }}</p>
        </div>
        <img class="bold_img" src="./unionLogo.jpg" />
        <div class="cover2">
          <p>企</p>
          <p>业</p>
          <p>工</p>
          <p>会</p>
          <p>销</p>
          <p>售</p>
          <p>人</p>
          <p>员</p>
          <p>劳</p>
          <p>务</p>
          <p>合</p>
          <p>同</p>
        </div>
        <div class="part1">
          <div class="cover-data cover-data1">
            <span class="titles">姓名：</span>
            <span class="text">{{ shareDate.name }}</span>
          </div>
          <p class="cover-data">
            签订日期： <span class="word-empty">{{ dataTime.split("-")[0] }}</span
            >年 <span class="word-empty">{{ dataTime.split("-")[1] }}</span
            >月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span
            >日
          </p>
        </div>
      </div>

      <div class="page" style="margin-top:60px">
        <img class="img_page" src="./unionLogo.jpg" />
        <div class="title-text">企业工会销售人员劳务合同</div>
        <div class="title_box">
          <span class="bold">甲方：</span>
          <span>湖北利道投资控股股份有限公司</span>
        </div>
        <p>法定代表人：成长运</p>
        <p>住所地：武汉市江岸区金桥大道115号长江传媒大厦主楼13层1305号</p>
        <div class="data">
          <span class="titles bold">乙方：</span>
          <span class="text">{{ shareDate.name }}</span>
        </div>
        <div class="data">
          <span class="titles">身份证号码：</span>
          <span class="text">{{ shareDate.Idcard }}</span>
        </div>
        <div class="data">
          <span class="titles">收件地址：</span>
          <span class="text">{{ shareDate.address }}</span>
        </div>
        <div class="data">
          <span class="titles">联系电话：</span>
          <span class="text">{{ shareDate.phone }}</span>
        </div>
        <div class="part">
          <p class="zindex">
            根据《中华人民共和国民法典》及有关法律法规规定，甲、乙双方本着平等自愿、诚实守信的原则，就双方的职责协商一致，签订本劳务合同，并承诺共同遵守。
          </p>
        </div>
        <div class="part">
          <span class="bold">一、合同类型和期限</span>
          <p class="zindex">
            本合同为劳务合同，劳务期限采用固定期限，自
            <span class="word-empty">{{ dataTime.split("-")[0] }}</span
            >年 <span class="word-empty">{{ dataTime.split("-")[1] }}</span
            >月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span
            >日起至 <span class="word-empty">{{ dataTime1.split("-")[0] }}</span
            >年 <span class="word-empty">{{ dataTime1.split("-")[1] }}</span
            >月 <span class="word-empty">{{ dataTime1.split("-")[2] }}</span
            >日止。
          </p>
        </div>
        <div class="part">
          <span class="bold">二、工作内容</span>
          <p class="zindex">
            甲方对乙方的工作内容无硬性要求，由乙方自主决定从事与甲方公司业务相关的销售工作。
          </p>
        </div>
      </div>

      <div class="page page_box">
        <div class="part">
          <span class="bold">三、劳务费</span>
          <p class="zindex">
            劳务费以绩效工资方式进行核算，根据乙方业绩考核情况核定。甲方对乙方的工作数量无硬性要求，最终以乙方实际完成的销售量并按双方约定的提成比例进行销售佣金结算。销售佣金结算按健德购购商城佣金的相关规则执行。
          </p>
        </div>
        <div class="part">
          <span class="bold">四、劳务合同的变更、解除、终止</span>
          <p>有下列情形之一的，甲、乙双方可以变更或解除本合同：</p>
          <p>1、在不损害国家、集体和他人利益的情况下，双方协商一致的。</p>
          <p>2、劳务合同订立时所依据的客观情况发生了重大变化，经与乙方协商一致的。</p>
          <p>3、由于不可抗力因素致使劳务合同无法完全履行的。</p>
          <p>
            4、以欺诈、胁迫的手段或者乘人之危，使甲方在违背真实意思的情况下订立或者变更劳务合同的。
          </p>
          <p>5、乙方被依法追究刑事责任的。</p>
        </div>

        <div class="part">
          <span class="bold">五、其他</span>
          <p>
            1、甲、乙双方之间不存在任何形式的劳动关系，劳务期限内甲方不为乙方缴纳任何社会保险费用。
          </p>
          <p>2、提供劳务期间发生的一切人身损害及相关责任均由乙方自行承担，与甲方无关。</p>
          <p>3、本合同一式两份，自甲、乙双方签字或盖章后立即生效，甲、乙双方各执一份。</p>
          <p>
            4、因履行本合同发生争议，由甲、乙双方协商解决。协商不成的，任何一方均有权向甲方住所地人民法院提起诉讼。
          </p>
        </div>
        <div class="part2">
          <div class="footer">
            <div class="item">
              <div>甲方（盖章）：</div>
              <div class="img_box">
                <img src="./group.png" />
              </div>
              <p>
                <span class="word-empty">{{ dataTime.split("-")[0] }}</span
                >年 <span class="word-empty">{{ dataTime.split("-")[1] }}</span
                >月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span
                >日
              </p>
            </div>
            <div class="item">
              <span>乙方（签字）：</span>
              <div style="" class="img_box1">
                <img style="" :src="shareDate.nameImg" alt="无" />
              </div>
              <p>
                <span class="word-empty">{{ dataTime.split("-")[0] }}</span
                >年 <span class="word-empty">{{ dataTime.split("-")[1] }}</span
                >月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span
                >日
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="neth_btn_e confim_btn" @click="nethBtnE">已同意</div>
  </div>
</template>

<script>
import html2Canvas from "html2canvas";
// import Watermark from "@/utils/watermark";
import JsPDF from "jspdf";
import { Toast } from "vant";
export default {
  components: {},
  props: {
    shareDate: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dataTime: {
      type: String,
    },
    dataTime1: {
      type: String,
    },
    imgToken: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {
  
  },
  methods: {
    nethBtnE() {
      window.scrollTo(0, 0);
      this.cloneBtn1();
      this.$emit("confim", 1);
    },
    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    cloneBtn1() {
      this.showText = true;
      Toast.loading({
        message: "生成中...",
        forbidClick: true,
      });
      let refsBox = document.querySelector("#pdfDom");
      let this_ = this;
      html2Canvas(refsBox, {
        useCORS: true,
        scale: 4,
      })
        .then(function (canvas) {
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageHeight = (contentWidth / 592.28) * 841.89;
          let leftHeight = contentHeight;
          let position = 0;
          let imgWidth = 595.28;
          let imgHeight = (592.28 / contentWidth) * contentHeight;
          let pageData = canvas.toDataURL("image/JPEG", 1.0); //压缩的base64，PDF文件
          let PDF = new JsPDF("", "pt", "a4");
          // PDF.setFont("simsun");
          console.log(PDF, "pdf");
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              if (leftHeight > 0) {
                PDF.addPage();
              }
            }
          }
          let pagurl = PDF.output("datauristring");
          this_.pdfURL = pagurl;
          console.log(this_.pdfURL, "3213213");
          let blob = this_.dataURLtoBlob(this_.pdfURL);

          let file = this_.blobToFile(blob, "PDF");
          let that = this_;
          let url = "https://up-z2.qiniup.com";
          let xhr = new XMLHttpRequest();

          xhr.open("POST", url, true);
          let formData = new window.FormData();
          console.log(formData, "3213213");
          formData.append("file", file);
          formData.append("token", that.imgToken);
          formData.append("config", "{ useCdnDomain: true }");
          xhr.send(formData);
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              let link = JSON.parse(xhr.responseText);
              if (link.key == undefined || link.key == null || link.key == "") {
                this.$message.error("错误，请重新生成合同");
              }
              that.imgUrl = `https://cdn.health.healthplatform.xyz/${link.key}`;
              // resolve()
              // Watermark.clear();
              console.log(that.imgUrl);
              that.$emit("nethBtn",that.imgUrl)
            }
          };
        })
        .catch((err) => {
          console.log(err);
          // alert(11111)
          Toast("pdf生成失败");
        });
    },
    //将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.cover-data{
  margin-bottom: 30px
}
#pdfDom{
  padding:0;
  height: 100%;
  width: 900px;
}
.out-wrap {
  .page {
    width: 100%;
    // height: 1100px;
    padding: 100px 70px;
    font-size: 18px;
    color: #000;
    font-family: "NSimSun";
    // border-bottom: 2px dashed #ccc;
    box-sizing: border-box;
    .bold_img{
      width: 60%; margin: 0px auto 0; display: block
    }
    .title-text {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 70px;
      font-size: 26px;
      font-weight: bold;
    }
    .title_box{
      margin-top: 80px
    }
    .img_page{
      width: 80%; margin: 0 auto; display: block
    }
  }
  .page_box{
    // height: 800px;
  }
}

.title-line {
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}
p {
  margin: 0;
  line-height: 1.5;
}
.word,
.word-empty {
  display: inline-block;
  border-bottom: 1px solid black;
  padding: 0px 5px;
  text-indent: 0px;
  text-align: center;
}
.word-empty {
  width: 60px;
}
.part {
  margin: 30px 0px 30px;
  
  .sub-item {
    padding-left: 20px;
  }
}
.part1{
  margin: 30px 0px;
  
  .sub-item {
    padding-left: 20px;
  }
  margin-top: 50px;
}

.part2{
  margin: 30px 0px;
  
  .sub-item {
    padding-left: 20px;
  }
  margin-top: 50px;
}
.bold {
  display: inline-block;
  margin-bottom: 3px;
  font-weight: bold;
}

.data {
  display: flex;
  align-items: flex-end;
  // height: 30px;
  margin-top: 5px;
  .titles {
  }
  .text {
    flex-grow: 1;
    width: 70%;
    border-bottom: 1px solid #000;
    text-align: left;
  }
}

.zindex {
  text-indent: 30px;
  margin-top: 10px;
}

.sp-stock {
  font-weight: bold;
  display: inline-block;
  border-bottom: 1px solid #000;
  text-indent: 0;
  padding: 0 10px;
}
.cover {
  width: 100%;
  height: 400px;
  font-size: 35px;
  margin-top: 200px;
  p {
    margin-bottom: 20px;
    text-align: center;
  }
}
.cover2 {
  width: 100%;
  height: 500px;
  font-size: 22px;
  margin-top: 30px;
  p {
    text-align: center;
  }
}

.cover-data {
  width: 100%;
  height: 30px;
  margin-top: 5px;
  text-align: center;
  .titles {
  }
  .text {
    display: inline-block;
    width: 250px;
    border-bottom: 1px solid #000;
    text-align: center;
  }
}
.footer {
  display: flex;
  .item {
    width: 50%;
    height: 300px;
    .img_box {
      position: relative;
      width: 270px;
      height: 200px;
      img {
        position: absolute;
        width: 180px;
        top: -40px;
        left: 100px;
      }
    }
    .img_box1 {
      position: relative;
      width: 270px;
      height: 200px;
      img {
        transform: rotate(-90deg);
        height: 200px;
        position: absolute;
        top: -40px;
        left: 150px;
      }
    }
  }
}
.neth_btn_e {
  width: 600px;
  height: 80px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin: 221px auto 100px;
  text-align: center;
  line-height: 80px;
}

.confim_btn {
  position: fixed;
  bottom: 50px;
  left: 75px;
}
</style>

<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>

    <!-- 销售合同 -->
    <div>
      <application-formNew
        :shareDate="shareDate"
        :dataTime="dataTime"
        :dataTime1="dataTime1"
        :imgToken="imgToken"
        :nameImg="union_shareholder_info.nameImg"
        @cloneBtn="cloneBtn"
        @confim="confim"
        @nethBtn="nethBtn"
        @submit="submit"
      ></application-formNew>
    </div>

    <!-- 签字弹窗 -->
    <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature
          :imgWidth="imgWidth"
          @signatureBtn="signatureBtn"
          @cloneBtn="cloneBtn1"
          :imgToken="imgToken"
          @cloneImg="cloneImg"
          @imgUblod="imgUblod"
        ></catSignature>
      </div>


    </div>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import applicationFormNew from "../../components/expenditure/applicationFormNew";
// import uploadImg from "../../components/uploadImg";
import catSignature from "../../components/catSignature.vue";
// 数据
import { ImagePreview } from "vant";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      headerConfig: {
          show: true,
          title: '合约续签',
      },

      signtureShow: false,
      imgToken: "", //上传图片token
      show: false,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      isNameShow: true,
      active: 0,
      union_shareholder_info: {
        nameImg: "",
        userAddress: "",
        name: "",
        Idcard: "",
        phone: "",
        // contractCode: "",
      },
      tableDate: {},
      userFrom: {
        union_info: {
          bank: {},
        },
      },
      shareDate: {},
      options: {
        status: "",
      },
      dataTime: "",
      dataTime1: "",
      type: "",
    };
  },
  components: {
    newHeader,
    applicationFormNew,
    // uploadImg
    catSignature,
  },
  created() {
    this.type = this.$route.query.type;
    document.title = "";
    this.getToken();
    this.getUserList()
    this.getdateNoTime(new Date().getTime());
  },
  methods: {
    getUserList() {
        apiCommon.getuser_api().then((res) => {
        if (res.code == 0) {
          this.userFrom = res.data;
          this.shareDate = res.data.union_shareholder_info;
        //   if (!this.userFrom.renew) {
        //     this.userFrom.renew = {};
        //   }
        //   if (!this.userFrom.renew) {
        //     this.userFrom.shareholder_renew = {};
        //   }
        //   if (this.userFrom.union_expire_at) {
        //     if (
        //       new Date().getTime() + 2592000000 >
        //       new Date(this.userFrom.union_expire_at).getTime()
        //     ) {
        //       this.userFrom.vipUser = 1;
        //     } else {
        //       this.userFrom.vipUser = 0;
        //     }
        //   } else {
        //     this.userFrom.vipUser = 3;
        //   }
        //   if (this.userFrom.union_shareholder_expire_at) {
        //     if (
        //       new Date().getTime() + 2592000000 >
        //       new Date(this.userFrom.union_shareholder_expire_at).getTime()
        //     ) {
        //       this.userFrom.vipShare = 1;
        //     } else {
        //       this.userFrom.vipShare = 0;
        //     }
        //   } else {
        //     this.userFrom.vipShare = 3;
        //   }

          // 合同编号
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.shareDate.contractCode = `LDGH-${year}${month < 10 ? "0" + month : month}${
          day < 10 ? "0" + day : day
        }${this.shareDate.phone.slice(-4)}`;
        }
      });
    },
   
    nethBtn(img) {
      this.shareDate.contract_img_1 = img;
      localStorage.setItem("shareDate", JSON.stringify(this.shareDate));
    this.submit();
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return Toast(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
    },
    cloneBtn() {},
    confim() {},
    getdateNoTime(date) {
      var now = new Date(parseInt(date)),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
      this.dataTime = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      this.dataTime1 =
        y + 3 + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    },
    submitBtn() {
      this.show = true;
    },
    // 签名
    singinBtn() {
      this.signtureShow = true;
    },
    cloneImg() {
      this.signtureShow = false;
    },
    cloneBtn1() {
      this.signtureShow = false;
    },
    // 预览
    namePreview() {
      ImagePreview([this.union_shareholder_info.nameImg]);
    },

    // 合同
    nethBtnE() {
      localStorage.setItem("shareDate", JSON.stringify(this.union_shareholder_info));
      localStorage.setItem("active", 1);
      this.getUser();
      if (this.type == 1) {
        this.active = 1;
      } else if (this.type == 2) {
        this.active = 2;
      } else {
        this.active = 1;
      }
    },

    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout((e) => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      this.signtureShow = false;
      this.union_shareholder_info.nameImg = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    },

    submit() {
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      let shareDate = JSON.parse(localStorage.getItem("shareDate"));
      let data = {
        contract_file: shareDate.contract_img_1,
        union_shareholder_info: {
          address: shareDate.userAddress,
          userAddress: shareDate.userAddress,
          nameImg: shareDate.nameImg,
          Idcard: shareDate.Idcard,
          phone: shareDate.phone,
          name: shareDate.name,
        },
      };
      apiCommon.shareholderRenew(data).then((res) => {
        if (res.code == 0) {
          Toast("提交成功");

          localStorage.removeItem("shareDate");
          this.$router.go(-1);
        }else{
            Toast(res.error);
        }
      });
    },
  },
};
</script>
<style scoped>
.contaner_w {
  /* background-color: #F9F9FB; */
  width: 100%;
}

.message_e {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.8);
}

.message_e span {
  color: #078bff;
}

.enterprise_content {
  padding: 0 50px;
  box-sizing: border-box;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.8);
}

.top_text {
  margin: 20px 0 30px;
  line-height: 40px;
}

.info_text {
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #1a1a1a;
  margin-top: 57px;
}

.info_text .bar {
  width: 6px;
  height: 24px;
  background: #078bff;
  border-radius: 0px 0px 0px 0px;
  margin: 5px 10px 0 0;
}

.from_box1 {
  display: flex;
  line-height: 66px;
  margin-top: 35px;
}

.from_box1 div {
  width: 120px;
  text-align: right;
  margin-right: 15px;
}

.from_box1 input {
  width: 500px;
  height: 66px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  padding: 16px 21px;
  box-sizing: border-box;
  border: none;
}

textarea {
  width: 500px;
  height: 120px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  border: none;
  padding: 20px 10px;
  box-sizing: border-box;
}

.from_box1 .singin_box {
  width: 500px;
  height: 180px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  padding: 19px 163px;
  box-sizing: border-box;
}

.singin_box img {
  width: 160px;
  height: 142px;
  border-radius: 0px 0px 0px 0px;
  transform: rotate(-90deg);
}

.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.signtureShow_box {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fed3a8 0%, #f6ebda 100%);
  margin: 0px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}

.neth_btn_e {
  width: 600px;
  height: 80px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin: 221px auto 100px;
  text-align: center;
  line-height: 80px;
}

.confim_btn {
  position: fixed;
  bottom: 50px;
  left: 75px;
}
</style>
